.navBarMobile {
  height: calc(100vh - 79px);
  color: #333;
  z-index: 1000;
  overflow: hidden;
}
.navBarMobile .navBar_mobile_scrollArea {
  max-height: unset;
}
.navBar_mobile_nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nav_parent_name,
.nav_parent_name_inside {
  color: #333;
  font-weight: 800;
  margin: 0.5rem 1rem 1rem;
  font-size: 1.1rem;
  border-bottom: 1px solid #999;
  padding: 0.5rem 0;
}
.nav_parent_name a {
  color: #333;
  font-weight: 800;
  padding: 0;
  line-height: 1.5;
  font-family: "proxima-nova", sans-serif;
  text-transform: capitalize;
  font-size: 1.1rem;
}
.nav_parent_name_inside {
  font-size: 1.5rem;
}
.nav_parent_desc {
  color: #333;
  margin: 0 1rem;
}
.nav_parent_item {
  position: relative;
  transition: all 0.3s ease;
  &:hover,
  &:active,
  &:focus {
    border-left: 5px solid #176b91;
    transition: all 0.3s ease;
  }
}
.nav_children_wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.nav_children_wrapper .nav_parent_btn {
  border: 1px solid #333;
  text-align: center;
  padding: 0.5rem 0;
  margin: 1rem 1rem 2rem;
  font-weight: 500;
  color: #333;
  text-transform: none;
}
.nav_children_list {
  height: auto;
}
.nav_children {
  margin: 1rem;
  font-weight: 700;
  color: #176b91;
  font-size: 1.2rem;
}
a.nav_children_link {
  color: #333;
  margin: 1rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid #999;
  transition: all 0.3s ease;
  font-family: "proxima-nova", sans-serif;
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    padding: 0.5rem;
    border-left: 5px solid #176b91;
    transition: all 0.3s ease;
  }
}
.nav_back_button {
  margin: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}
.nav_back_button svg {
  margin-right: 0.2rem;
  font-size: 1.3rem;
}
.bottomLinks {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.bottomLinks a {
  color: #333;
  font-weight: 800;
  margin: 0 1rem 1rem;
  font-size: 1rem;
  border-top: 1px solid #999;
  padding: 1rem 0 0.5rem;
}
.back_button_styled {
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.bottomLinkClientPortal {
  color: #333;
  font-weight: 800;
  margin: 0 1rem 1rem;
  font-size: 1rem;
  border-top: 1px solid #999;
  padding: 1rem 0 0.5rem;
  line-height: 1;
  transition-duration: 0.3s;
  text-transform: uppercase;
  font-family: "futura-pt", sans-serif;
}
