.navBarDesktop {
  width: 100vw;
  height: auto;
  max-width: 100vw;
  border-bottom: 2px solid #176b91;
  transform: translateY(-100%);
  opacity: 0;
  z-index: 990;
}
.navBarDesktop--on {
  transform: translateY(0);
  opacity: 1;
}
.navBar_desktop_scrollArea {
  max-height: unset;
}
.navBar_desktop_nav {
  margin: 0 auto;
  color: #333;
  display: flex;
  font-size: 0.9rem;
  @media screen and (min-width: 1600px) {
    font-size: 1rem;
  }
}
.left_navBar {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding-bottom: 3rem;
  margin-left: 2rem;
  @media screen and (min-width: 1366px) {
    margin-top: 2rem;
    margin-left: 3rem;
  }
  @media screen and (min-width: 1920px) {
    margin-left: 5rem;
  }
  @media screen and (min-width: 2500px) {
    margin-left: 15rem;
    padding-bottom: 5rem;
  }
}
.left_item,
.left_item_selected {
  max-width: 180px;
  margin: 0 0 1rem 1rem;
  padding: 0 20px;
  position: relative;
  transition: all 0.3s ease;
  border-left: 5px solid transparent;
  cursor: pointer;
  @media screen and (min-width: 1200px) {
    max-width: 170px;
    margin: 0 0 1rem 0.8rem;
    padding: 0 13px;
  }
  @media screen and (min-width: 1366px) {
    max-width: 180px;
    margin: 0 0 1rem 0;
    padding: 0 17px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 215px;
    margin: 0 0 2rem 1rem;
    padding: 0 13px;
  }
  @media screen and (min-width: 1920px) {
    max-width: 250px;
  }
  @media screen and (min-width: 2500px) {
    margin: 0 2rem 2rem;
    padding: 0 20px;
  }
}
.left_item:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #176b91;
  width: 5px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.3s ease;
}
.left_item:hover:before,
.left_item:focus:before,
.left_item:active:before {
  transform: scaleY(1);
}
.left_item_selected {
  border-left: 5px solid #176b91;
}
.left_item_name {
  font-weight: 800;
  margin-bottom: 0.7rem;
}
.left_navBar a.left_item_wrap {
  font-weight: 800;
  margin-bottom: 0.7rem;
  color: #333;
  padding: 0;
  transition-duration: unset;
  font-family: "proxima-nova", sans-serif;
  line-height: 1.5;
}
.left_item_wrap .left_item_name {
  text-transform: capitalize;
}
.left_item_wrap .left_item_des {
  font-weight: 300;
  text-transform: none;
}
.left_item_des,
.right_item_des {
  color: #999;
}
.right_navBar {
  background-color: #f5f5f5;
  padding: 1.5rem 1rem 1.5rem 3rem;
  width: 50%;
  height: 383px;
  @media screen and (min-width: 1600px) {
    height: 426px;
  }
  @media screen and (min-width: 2500px) {
    padding: 1.5rem 2.5rem;
    height: 450px;
  }
}
.right_item {
  max-width: 250px;
  padding-bottom: 1rem;
  @media screen and (min-width: 2500px) {
    padding-bottom: 2rem;
  }
}
.right_item .line {
  margin: 0 0 0.7rem;
  background: #000;
  height: 1px;
  width: 100%;
}
.right_content_links {
  @media screen and (min-width: 2500px) {
    flex-flow: column wrap;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 320px;
  }
}
a.right_item_name,
a.right_content_link,
a.right_content_name {
  padding: 0.5rem 0;
  color: #333;
  font-weight: 800;
  font-family: "proxima-nova", sans-serif;
  text-transform: none;
  font-size: 0.9rem;
  @media screen and (min-width: 1600px) {
    font-size: 1rem;
  }
}
a.right_content_name::after {
  content: "";
  background-image: url("/assets/arrow-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
  display: inline-block;
  margin-left: 0.8rem;
}
a.right_content_name:hover::after {
  transform: translateX(10px);
}
a.right_item_name:hover,
a.right_content_link:hover,
a.right_content_name:hover,
a.right_item_name:focus,
a.right_content_link:focus,
a.right_content_name:focus {
  color: #333;
  background-color: unset;
}
.right_item_content {
  @media screen and (max-width: 2500px) {
    height: 100%;
    overflow-y: scroll;
  }
}
.right_item_content .right_content_name {
  font-weight: 800;
  margin-bottom: 0.5rem;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0;
  line-height: 1;
}
.right_item_content .line {
  margin: 0 0 0.7rem;
  background: #777;
  height: 1px;
  width: 100%;
}
a.right_content_link {
  color: #777;
  font-weight: normal;
  height: 35px;
  line-height: 35px;
  padding: 0 0 0 10px;
  margin: 0 3.5rem 5px 0;
  border-left: 3px solid transparent;
  text-transform: capitalize;
  &:hover,
  &:focus,
  &:active {
    padding: 0 0 0 10px;
    border-left: 3px solid #176b91;
  }
}

// top header
.topHeaderLogo {
  @media screen and (min-width: 576px) {
    position: fixed;
    left: 6.5%;
  }
  @media screen and (min-width: 1200px) {
    left: 4.5%;
  }
  @media screen and (min-width: 1920px) {
    left: 5.3%;
  }
  @media screen and (min-width: 2500px) {
    left: 12.5%;
  }
}
.topLinks {
  position: fixed;
  right: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.topLinks a {
  margin-right: 3rem;
  color: #333;
  font-weight: 500;
  font-size: 0.9rem;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}
.topHeaderCopy {
  background: #f5f5f5;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 74px;
  text-align: center;
  font-weight: 500;
  height: 45px;
  line-height: 48px;
  transition: 0.3s all;
  font-size: 0.8rem;
  @media screen and (max-width: 576px) {
    height: 30px;
    line-height: 30px;
    top: 44px;
  }
}
.topHeaderCopyScrolled {
  top: 64px;
  transition: 0.3s all;
  @media screen and (max-width: 576px) {
    top: 44px;
  }
}
.topHeaderLine {
  height: 10px;
  background: #176b91;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 119px;
  transition: 0.3s all;
  @media screen and (max-width: 576px) {
    height: 5px;
    top: 74px;
  }
}
.topHeaderLineScrolled {
  top: 109px;
  transition: 0.3s all;
  @media screen and (max-width: 576px) {
    top: 74px;
  }
}
