.navBar {
  position: fixed;
  top: map-get($map: $top-bar-height, $key: mobile);
  width: 100%;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  color: white;
  z-index: 999;
  opacity: 0.3;
  transition: all 0.3s;
  transform: translateX(100%);
  right: 0;
  @include media-breakpoint-up(md) {
    top: map-get($map: $top-bar-height, $key: tablet);
  }
  @include media-breakpoint-up(lg) {
    top: map-get($map: $top-bar-height, $key: desktop);
  }
  .scrolled &,
  .subpage & {
    top: map-get($map: $top-bar-height, $key: mobile);
    @include media-breakpoint-up(lg) {
      top: map-get($map: $top-bar-height, $key: desktop);
    }
  }
}
.body-content .navBarScrolled {
  top: map-get($map: $top-bar-scroll-height, $key: mobile);
  @include media-breakpoint-up(lg) {
    top: map-get($map: $top-bar-scroll-height, $key: desktop);
  }
}

.navBar--on {
  transform: translateX(0);
  opacity: 1;
}

// navBar menu list common
.navBar_nav {
  margin: 0;

  ul,
  li {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
  }

  li {
    position: relative;
    margin: 0.5rem 0;
  }

  a {
    display: block;
    color: #073559;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    transition-duration: 0.3s;
    text-transform: uppercase;
    font-family: "futura-pt", sans-serif;
    &:hover,
    &:focus {
      text-decoration: none;
      color: #65bb3e;
      background-color: #fff;
    }
  }
}
