.contentPanel {
  background: white;
  padding: 1rem 0;
  position: relative;
  word-break: break-word;
  background-size: 100%;
  background-repeat: repeat;
  background-position: center;

  > * {
    z-index: 1;
    position: relative;
  }

  h2 a.button {
    font-size: 2.6rem;
  }

  h3 a.button {
    font-size: 2.2rem;
  }

  h4 a.button {
    font-size: 1.2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }

  a.button {
    color: #fff;
    background: #65bb3e;
    border: 1px solid #65bb3e;
    border-radius: 6px;
    padding: 0.4rem 1rem;
    font-weight: 400;
    display: inline-block;
    &:focus,
    &:hover {
      background: #073559;
      border: 1px solid #073559;
      text-decoration: none;
    }
  }
  a.button.secondary {
    color: #fff;
    background: #073559;
    border: 1px solid #073559;
    border-radius: 6px;
    padding: 0.4rem 1rem;
    font-weight: 400;
    display: inline-block;
    &:focus,
    &:hover {
      background: #65bb3e;
      border: 1px solid #65bb3e;
      text-decoration: none;
    }
  }
  a.button.third {
    color: #073559;
    background: transparent;
    border: none;
    border-bottom: 2px solid #073559;
    border-radius: 0;
    padding: 0.4rem;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease;
    font-weight: 400;
  }
  a.button.third::after {
    content: "";
    background-image: url("/assets/arrow-right.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }
  a.button.third:hover::after {
    transform: translateX(5px);
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}

//override bootstrap container size
.container {
  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
}
