.pageTiles.pageTiles_small2.useSlider .tile {
  @media screen and (max-width: 576px) {
    padding: 10px;
  }
}
.pageTiles.pageTiles_small2 .slick-prev,
.pageTiles.pageTiles_small2 .slick-next {
  top: 50%;
}
.pageTiles.pageTiles_small2 .slick-prev:before,
.pageTiles.pageTiles_small2 .slick-next:before {
  color: black;
}
.pageTiles.pageTiles_small2 .slick-prev {
  left: -25px;
  @media screen and (max-width: 576px) {
    left: -12px;
  }
}
.pageTiles.pageTiles_small2 .slick-next {
  right: -25px;
  @media screen and (max-width: 576px) {
    right: -12px;
  }
}
.pageTile2__content {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  background: #fff;
  color: $font-color;
  box-shadow: 0 0 10px 2px rgba(190, 190, 190, 0.7);
  line-height: 1.1;
  text-align: left;
  overflow: hidden;
  &:hover,
  &:focus {
    color: $font-color;
    text-decoration: none;
    .pageTile_small2_content {
      background: #176b91;
      transition: all 0.2s;
    }
    .pageTile2__title,
    .pageTile2__desc {
      transition: all 0.2s;
      color: white;
    }
    // .pageTile_small2_bg {
    //   transform: scale(1.02);
    // }
  }
}
.pageTile_small2_bg {
  // height: 232px;
  height: 231px;
  padding-top: 0;
  // background-position: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  // background-attachment: fixed;
  transition: all 0.2s;

  @media screen and (max-width: 1600px) {
    height: 200px;
  }

  @media screen and (max-width:1201px) {
    height: 167px;
  }

  @media screen and (max-width:993px) {
    background-size: cover;
    height: 188px;
  }

  @media screen and (max-width:767px) {
    background-size: cover;
    height: 138px;
  }

  @media screen and (max-width: 576px) {
    background-size: cover;
    height: 205px;
  }
}
.pageTile_small2_content {
  width: 100%;
  position: relative;
  overflow: hidden;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding: 0.5rem 1.2rem 1.5rem;
  text-align: left;
  background-color: #fff;
  min-height: 170px;
  @media screen and (max-width: 576px) {
    max-height: 150px;
    min-height: 150px;
  }
}
.pageTile2__title {
  font-family: $family-header;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  margin: 0.8rem 0 1rem;
  transition: all 0.2s;
}
.pageTile2__desc {
  line-height: 1.3;
  font-size: 0.9rem;
  color: #7a7a7a;
  font-weight: 300;
  min-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: all 0.2s;
  @media screen and (max-width: 576px) {
    min-height: 60px;
    line-height: 1.2;
    font-size: 1rem;
  }
}
