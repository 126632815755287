$breakpoints: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 3,
);
$margin: 20px;

.blogTiles {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }
  &__loadMore {
    margin-bottom: 2rem;
    .thinBtn {
      width: 100%;
      display: block;
      color: white;
      background-color: $font-color;
      cursor: pointer;
      &:hover,
      &:focus {
        color: $font-color;
        background-color: white;
      }
    }
  }
  &--productView .blogTile {
    @include media-breakpoint-up(xxl) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}

.blogTile {
  padding: 10px 0;
  @media screen and (min-width: 576px) {
    padding: 20px;
  }
  @each $bp, $number in $breakpoints {
    @include media-breakpoint-up(#{$bp}) {
      flex: 0 0 100% / $number;
      max-width: 100% / $number;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background: #fff;
    color: $font-color;
    box-shadow: 0 0 10px 2px rgba(190, 190, 190, 0.7);
    line-height: 1.1;
    text-align: left;
    overflow: hidden;
    &:hover,
    &:focus {
      color: $font-color;
      text-decoration: none;
      .blogTile__content {
        background: #176b91;
        transition: all 0.2s;
      }
      .blogTile__title,
      .blogTile__desc {
        transition: all 0.2s;
        color: white;
      }
      .blogTile__bg {
        transform: scale(1.02);
      }
    }
  }

  &__bg {
    height: 235px;
    padding-top: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.2s;
    @media screen and (max-width: 576px) {
      height: 185px;
    }
  }

  &__content {
    width: 100%;
    position: relative;
    overflow: hidden;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    padding: 0.5rem 1.2rem 1.5rem;
    text-align: left;
    background-color: #fff;
    min-height: 200px;
    @media screen and (max-width: 576px) {
      max-height: 150px;
      min-height: 150px;
    }
  }

  &__title {
    font-family: $family-header;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1;
    margin: 0.8rem 0 1rem;
    transition: all 0.2s;
    @media screen and (max-width: 576px) {
      // font-size: 1.2rem;
      // margin-top: 0;
    }
  }

  &__desc {
    line-height: 1.3;
    font-size: 0.9rem;
    color: #7a7a7a;
    font-weight: 300;
    min-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transition: all 0.2s;
    @media screen and (max-width: 576px) {
      min-height: 60px;
      line-height: 1.2;
      font-size: 1rem;
    }
  }
}
.blogTile:nth-child(3n + 1) {
  @media screen and (min-width: 576px) {
    padding-left: 10px;
  }
}
.blogTile:nth-child(3n) {
  @media screen and (min-width: 576px) {
    padding-right: 10px;
  }
}

.thinBtn {
  background-color: #fff;
  border: 1px solid $font-color;
  border-radius: 0;
  font-size: 0.8rem;
  letter-spacing: 1px;
  transition: all 0.15s;
  margin: auto;
  width: 10em;
  padding: 0.5rem 1rem;
  text-align: center;
  margin-top: 2rem;
}
