// overriding bootstrap variables in _boostrap-override.scss
@import url("https://use.typekit.net/peq4uaq.css");

$family-base: "proxima-nova", sans-serif;
$family-header: "futura-pt", sans-serif;

// font-size
$font-size-desktop: 19px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-normal: $weight-regular;
$weight-semibold: 600;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #073559;
$color-blue2: #073577;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #65bb3e;
$color-gray: #333333;

// more colors
$color-gold: #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #063051;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-blue;
$font-color: #333333;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #fff;
$link-color: $color-green;
$link-hover-color: $color-dark-blue;

// top-bar
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
$top-bar-height: (
  mobile: 79px,
  small-device: 129px,
  tablet: 129px,
  desktop: 129px,
);
$top-bar-scroll-height: (
  mobile: 79px,
  small-device: 119px,
  tablet: 119px,
  desktop: 119px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
