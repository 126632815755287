.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &.bannerHasTitlePanel::before {
    content: "";
    background: rgba(0, 0, 0, 0.3333);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
  }
}

.banner_item__text {
  text-align: center;
  padding: 0.5rem 0 0.7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1200px;
  z-index: 10;
  font-family: $family-base;
  font-weight: 600;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.1;

  @include media-breakpoint-up(lg) {
    font-size: 2.3rem;
    margin-bottom: -0.3rem;
  }
}

.bannerItem__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.banner-item__container {
  text-align: left;
}
.banner-item__title {
  font-size: 2rem;
}
.banner-item__subTitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1.8rem 0 1rem;
}
.banner-item__desc {
  font-size: 1rem;
  font-weight: 400;
}
.banner-item__link {
  font-size: 1rem;
  color: #fff;
  background: #65bb3e;
  border: 1px solid #65bb3e;
  border-radius: 6px;
  padding: 0.4rem 1rem;
  font-weight: 400;
  display: inline-block;
  margin-top: 3rem;
  line-height: 1.5;
  &:hover,
  &:focus {
    color: #fff;
    background-color: $link-hover-color;
    border: 1px solid $link-hover-color;
    text-decoration: none;
  }
}
