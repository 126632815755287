%bannerSize--standard {
  height: 400px;

  @include media-breakpoint-up(sm) {
    // 576px and up
    height: 350px;
  }
  @include media-breakpoint-up(md) {
    // 768px and
    height: 400px;
  }
  @include media-breakpoint-up(lg) {
    // 992px and up
    height: 450px;
  }
  @include media-breakpoint-up(xl) {
    // 1200px and up
    height: 500px;
  }
  @media (min-width: 1601px) {
    height: 550px;
  }
  @media (min-width: 1901px) {
    height: 600px;
  }
  @media (min-width: 2201px) {
    height: 700px;
  }
}
