%footer-font {
  font-size: 0.7rem;
  color: white;

  &:hover,
  &:focus {
    color: white;
  }
}

.footerLinks {
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  li {
    margin: 0 0.7rem 0 0;
    padding: 0 0.7rem 0 0;
    list-style: none;
    border-right: 1px solid lighten($font-color, 50);
    @extend %footer-font;

    &:last-of-type {
      border-right: 0;
    }
  }

  a {
    @extend %footer-font;
  }
}

#contact > .container {
  max-width: 65%;
}
#contact > .container .multiColumnPanel,
#contact > .container .htmlPanel,
#contact > .container .multiColumnPanel > .container {
  max-width: 100%;
  width: 100%;
}
#contact > .container .htmlPanel a {
  color: #fff;
  line-height: 2;
  &:focus,
  &:hover {
    color: #65bb3e;
    text-decoration: none;
  }
}
