.main-content {
  padding-top: map-get($top-bar-height, mobile);
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    padding-top: map-get($top-bar-height, desktop);
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    color: #fff;
    background: #65bb3e;
    border: 1px solid #65bb3e;
    border-radius: 6px;
    padding: 0.4rem 1rem;
    font-weight: 400;
    display: inline-block;
    &:focus,
    &:hover {
      background: #073559;
      border: 1px solid #073559;
      text-decoration: none;
    }
  }
}
