.pageTiles.pageTiles_small {
  margin: 2rem -10px;
  @media screen and (max-width: 576px) {
    display: unset;
  }
  @media screen and (min-width: 1600px) {
    margin: 2rem -90px;
  }
}
.pageTiles.pageTiles_small .tile {
  @media screen and (max-width: 576px) {
    padding: 10px 0;
  }
}
.pageTiles.pageTiles_small .pageTile_small_content {
  //   aspect-ratio: 16 / 9;
  height: 14.5rem;
  background-size: cover;
  @media screen and (max-width: 576px) {
    height: 15.5rem;
  }
}
.pageTiles.pageTiles_small .pageTile__title {
  position: absolute;
  bottom: 1rem;
  // background: #06305s1;
  width: 85%;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem 0.7rem;
  color: white;
  font-weight: 300;
  font-family: "futura-pt", sans-serif;
  margin-bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease-in-out 0s;
  background: rgba(6, 48, 81, 0.77);
  backdrop-filter: blur(10px);
  @media screen and (max-width: 1199px) {
    font-size: 0.7rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }
}
.pageTiles.pageTiles_small .pageTile_desc_container {
  width: 100%;
  height: 100%;
  background: rgba(6, 48, 81, 0.77);
  backdrop-filter: blur(10px);
  padding: 1rem;
  transform: translateY(-100%);
  transition: all 0.4s ease-in-out 0s;
  visibility: hidden;
  // opacity: 0.9;
  @media screen and (min-width: 1600px) {
    padding: 1.8rem 2rem 1rem;
  }
}
.pageTiles.pageTiles_small .pageTile_desc {
  line-height: 1.3;
  font-size: 0.9rem;
}
.pageTiles.pageTiles_small .pageTile__content:hover .pageTile__title {
  transition: all 0.4s ease-in-out 0s;
  bottom: -70px;
}
.pageTiles.pageTiles_small .pageTile__content:hover .pageTile_desc_container {
  transform: translateY(0);
  transition: all 0.4s ease-in-out 0s;
  visibility: visible;
  // opacity: 0.9;
}
